export default class {
  // TODO: Consider using property getters but only after we've used TypeScript.

  /// Organization

  // static orgAuthToken() { // Private property
  //   return "authToken"
  // }

  ///

  /// Member

  static mbrAuthToken() { // Private property
    return "authToken"
  }

  // static mbrDefaultProjectId() { // Private property
  //   return "defaultProjectId"
  // }

  static mbrBoardId(rawId) { // Private property
    return `b:${rawId}`
  }

  ///
  // The more mutable properties in the same scope (e.g. a particular card), the higher the probability of data loss due to concurrent updates.
  // See https://developer.atlassian.com/cloud/trello/power-ups/client-library/getting-and-setting-data/

  /// Board

  // static brdAuthToken() { // Private property
  //   return this.orgAuthToken()
  // }

  static brdCycleStart() { // Mutable property
    return "cycleStart"
  }

  static brdCycleDuration() { // Mutable property
    return "cycleDuration"
  }

  static brdProjectIds() { // Mutable property
    return "projectIds"
  }

  static brdProjectId(rawId) { // Mutable property
    return `p:${rawId}`
  }

  ///

  /// Scope Card

  static crdProjectId() { // Immutable property
    return "projectId"
  }

  static crdHillPointX() { // Mutable property
    return "hillPointX"
  }

  static crdTaskCardIds() { // Mutable property
    return "taskCardIds"
  }

  static crdRiskLevel() { // Mutable property
    return "riskLevel"
  }

  ///


  /// Task Card

  static crdScopeCardId() { // Mutable property
    return "scopeCardId"
  }

  ///

  /// Local storage

  static lclBoardAutoLaunch(boardId) {
    return `tpu.${boardId}.autoLaunch`
  }

  ///

  /// Lock

  static lckCardMove() {
    return "card_move"
  }

  ///

  /// HTML element

  static htmScopePrefix() {
    return "scope_"
  }

  static htmTaskPrefix() {
    return "task_"
  }

  static toHtmScopeId(scopeCardId) {
    return `${this.htmScopePrefix()}${scopeCardId}`
  }

  static toHtmTaskId(taskCardId) {
    return `${this.htmTaskPrefix()}${taskCardId}`
  }

  static fromHtmScopeId(htmScopeId) {
    if (!htmScopeId.startsWith(this.htmScopePrefix())) {
      throw "Invalid HTML Scope ID"
    }

    return htmScopeId.replace(new RegExp(`^${this.htmScopePrefix()}`),'');
  }

  static fromHtmTaskId(htmTaskId) {
    if (!htmTaskId.startsWith(this.htmTaskPrefix())) {
      throw "Invalid HTML Task ID"
    }

    return htmTaskId.replace(new RegExp(`^${this.htmTaskPrefix()}`),'');
  }

  ///

  /// Icon

  static icnChecklist() {
    // return "library_add_check"
    return "playlist_add_check"
  }

  static icnDestroy() {
    // return "delete"
    return "delete_outline"
  }

  static icnAddScope() {
    // return "library_add"
    // return "add_circle_outline"
    return "add"
  }

  static icnOpenInNew() {
    return "open_in_new"
  }

  static icnOpenInCurrent() {
    return "open_in_browser"
  }

  // static icnSwitchModal() {
  //   return "open_in_full"
  // }

  static icnSearch() {
    return "search"
  }

  static icnEdit() {
    return "edit"
  }

  static icnRefresh() {
    return "refresh"
  }

  static icnCancel() {
    return "close"
  }

  static icnProject() {
    return "construction"
  }

  static icnAddMember() {
    return "person_add_alt_1"
  }

  static icnDescription() {
    // return "notes"
    // return "view_headline"
    return "subject"
  }

  static icnsRiskLevel() {
    return [
      null,
      'error_outline',
      'error',
    ]
  }

  ///
}
